function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.ENDED) {
        $.magnificPopup.close();
    }
}

$(document).ready(function(){

    // Menü anpinnen

    var nav = $('nav.navbar');
    var navOffsetTop = nav.offset().top;
    var globalNavHeight = $('.globalnav--barren').outerHeight();

    // Beim scrollen ausführen
    $(window).scroll(function(){

        var scrollPosToTop = $(this).scrollTop();

        // Wenn Menü die obere Viewportgrenze erreicht, fixieren
        if (scrollPosToTop > (navOffsetTop-globalNavHeight)){
            $('section.globalnav--barren').addClass('fixed-desktop');
            nav.addClass('fixed-desktop');
        } else {
            $('section.globalnav--barren').removeClass('fixed-desktop');
            nav.removeClass('fixed-desktop');
        }

    });

    if($('.main-slider').length > 0) {
        $('.main-slider').slick({
            slidesToShow: 1,
            dots: true,
            arrows: false
        });
    }



    if($('.project-slider').length > 0) {
        $('.project-slider').slick ({
            slidesToShow: 4,
            dots: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1010,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 526,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }



    if($('.project-detail__slider').length > 0) {
        $('.project-detail__slider').slick({
            slidesToShow: 1,
            dots: true,
            arrows: false,
            infinite: false
        });
    }



    $('.btn-search').on('click', function() {
        $(this).parent().toggleClass('open');
        $(this).next('form').find('input').focus();
    });

    $('.mobile-filter').click(function(){
       $(this).toggleClass('open').next('form.filter-left').slideToggle('fast');
    });

    $('[data-toggle="popover"]').popover();


    $('.popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        iframe: {
            markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-close"></div>'+
                '<iframe id="player" class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1&enablejsapi=1'
                }
            },
            srcAction: 'iframe_src'
        },
        callbacks: {
            open: function () {
                new YT.Player('player', {
                    events: {
                        'onStateChange': onPlayerStateChange
                    }
                });
            }
        },
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });

    $('.popup-image').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        }
    });

});

$('.zoom-view').click(function() {
    $('html').toggleClass('zoom'); // font-size: 20px
});

function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}


